<template>
  <Main>
    <ToolsPanel :breadCrumbFirst="'Главная'" :breadCrumbFirstLink="'/'" :title="'Главная'">
      <div class="form-create__buttons"></div>
    </ToolsPanel>
    <ContentContainer>
      <div class="home">
        <div v-if="quota" class="home__quota">
          <b>Оставшийся haygen quota:</b> {{ quota.remaining }}
        </div>
      </div>
    </ContentContainer>
  </Main>
</template>

<script>
import Main from "~/templates/Main.vue";
import ToolsPanel from "~/components/molecules/ToolsPanel.vue";
import ContentContainer from "~/components/molecules/ContentContainer.vue";
import usersApi from "~/api/user";

export default {
  name: "UserCreate",
  metaInfo: {
    title: "Главная",
  },
  data() {
    return {
      quota: null,
    };
  },
  components: {
    Main,
    ToolsPanel,
    ContentContainer,
  },
  mounted() {
    this.getQuota();
  },
  methods: {
    getQuota() {
      try {
        usersApi.getQuota().then((res) => {
          const data = res.data;
          this.quota = data;
        });
      } catch (error) {
        console.log(error);
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.home {
  &__title {
    font-size: 24px;
    padding-bottom: 15px;
    font-weight: bold;
  }
  &__wrap {
    display: flex;
    justify-content: space-between;
  }
  &__list-item {
    font-size: 18px;
    padding-bottom: 10px;
  }
}
</style>
